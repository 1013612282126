import Printed1 from "../../assets/3dprinted/catvoid.jpg"
import Printed2 from "../../assets/3dprinted/goldvase.jpg"
import Printed3 from "../../assets/3dprinted/ps4support.jpg"
import Printed4 from "../../assets/3dprinted/support.jpg"
import clay1 from "../../assets/clayprinted/1.png";
import clay2 from "../../assets/clayprinted/2.png";
import clay3 from "../../assets/clayprinted/3.png";
import ali1 from "../../assets/aliproduct/strn.png";
import ali4 from "../../assets/aliproduct/w.png";
import abdominalroller from "../../assets/aliproduct/abdominalroller.jpg";
import shaker from "../../assets/aliproduct/shaker.jpg";
import goku from "../../assets/3dprinted/goku.jpg"
import tiger from "../../assets/3dprinted/tiger.jpg"
import pain from "../../assets/3dprinted/pain.jpg"
import spider from "../../assets/3dprinted/spider.jpg"
import feath from "../../assets/3dprinted/feath.jpg"
import earring from "../../assets/3dprinted/earring.jpg"
import kunai from "../../assets/3dprinted/kunai.jpg"
import wall from "../../assets/3dprinted/wall.jpg"
import wallet from "../../assets/3dprinted/wallet.jpg"
import youda from "../../assets/3dprinted/youda.jpg"
import support from "../../assets/3dprinted/support.jpg"
import bic from "../../assets/3dprinted/bic.jpg"
import butterf from "../../assets/3dprinted/butterf.jpg"
import granoulas from "../../assets/granoula/gokugran.png"
import gymshark from "../../assets/aliproduct/Gymshark-Logo.jpg.png"
import gokushaker from "../../assets/aliproduct/goku dhzkrt.png"
import desk from "../../assets/3dprinted/desk.jpg"
import vrf from "../../assets/vrprinted/vrf.png"
import vitoris from "../../assets/aliproduct/vitoris.png"
import lighter from "../../assets/aliproduct/lighter.jpg"
import vase from "../../assets/3dprinted/vase.png"
import chair from "../../assets/3dprinted/chair.png"
//import clay4 from "../../assets/clayprinted/4.png";
/**
 * Product Database can be added here.
 * You can add products of your wish with different attributes
 * */
export const products = [

  
    {
      _id: "efdd52f6-4e35-4cd7-ad4a-0a9b52po894f",
      qty: 4,
      name: "Little Cow",
    description:
        "Clay homemade little cow",
      brand: "El karrita",
      category: "Clay",
      gender: "Men",
      weight: "30g",
      quantity: 10,
      image: clay1,
      rating: 4.5,
      price: 30,
      newPrice: 27,
      trending: true,
    },
    {
      _id: "efdd52f6-4e35-4cd7-ad4a-0a9b5bvv94f",
      qty: 4,
      name: "wall planter",
    description:
        "3D printed",
      brand: "El karrita",
      category: "printable",
      gender: "Men",
      arlink:"https://mahergrf.com/elkarrita/?editorid=583&model=https%3A%2F%2Fmahergrf.com%2Ftitouch%2Fvase.gltf",
      weight: "30g",
      quantity: 10,
      image: vase,
      rating: 4.5,
      price: 30,
      newPrice: 27,
      trending: true,
    },
    {
      _id: "efdd5ez-4e35-4cd7-ad4a-0a9b52po894f",
      qty: 4,
      name: "Kerosene Lighter",
    description:
        "kerosen",
      brand: "El karrita",
      category: "retail",
      gender: "Men",
      weight: "30g",
      quantity: 10,
      image: lighter,
      rating: 4.5,
      price: 30,
      newPrice: 27,
      trending: false,
    },
    {
      _id: "efdd52f6-4e35-4cd7-ad4a-0a9b5gf894f",
      qty: 4,
      name: "Virtual Rose",
    description:
        "Each flower comes with a custom name, adding a heartfelt touch to your gesture. With augmented reality technology, your message comes to life, creating a memorable and immersive experience.",
      brand: "El karrita",
      category: "virtual",
      gender: "unisex",
      weight: "30g",
      quantity: 99,
    
      image: vrf,
      rating: 4,
      price: 5,
      newPrice: 4,
      trending: false,
    },
    {
      _id: "efdd52f6-4e35-4cd7-ad4a-0a9bzee894f",
      qty: 4,
      name: "Dragon ball Shakers",
    description:
        "Introducing our Dragon Ball-themed protein shaker and creatine mini shaker! Fuel your workouts with the power of your favorite characters.",
      brand: "Whey Complex",
      category: "retail",
      gender: "unisex",
      weight: "50g",
      arlink:"https://mahergrf.com/elkarrita/?editorid=583&model=https%3A%2F%2Fmahergrf.com%2Ftitouch%2Fmaherkarita.gltf",
      quantity: 10,
      image: gokushaker,
      rating: 4.5,
      price: 55,
      newPrice: 50,
      trending: true,
    },
    {
      _id: "efdd52f6-4e35-4cd7-ad4a-0a9ddd894f",
      qty: 4,
      name: "GYMSHARK® Products",
    description:
        "Gym Shark dropshipping service. Take your workout to the next level with premium athletic wear delivered straight to your door! Simply pre-order your items, send us the item links or picture, and we'll handle the rest.",
      brand: "Gym Shark",
      category: "retail",
      gender: "unisex",
      //weight: "30g",
      //quantity: 10,
      image: gymshark,
      rating: 4.5,
      price: 150,
      newPrice: 130,
      trending: true,
    },
    {
      _id: "efdere2f6-4e35-4cd7-ad4a-0a9ddd894f",
      qty: 4,
      name: "Victoria's Secret® products",
    description:
        "Victoria Secret dropshipping service. Send us product links or picture, and we'll handle the rest.",
      brand: "Victoria Secret",
      category: "retail",
      gender: "Female",
      //weight: "30g",
      //quantity: 10,
      image: vitoris,
      rating: 4.5,
      price: 150,
      newPrice: 130,
      trending: false,
    },
    {
      _id: "887cdd99-1503-4527-aef4-88ZZ1126a47",
      qty: 55,
      name: "Granola Sayian edition",
      description:
        "Introducing Granola: Saiyan Edition – a caffeinated boost for breakfast! Carbohydrates: 65 grams, Protein:  15 grams, Fat:  15 grams, Fiber:  10 grams, Sugar:  25 grams",
      brand: "El Karrita",
      category: "Granola",
      //gender: "unisex",
      weight: "260g",
      quantity: 5,
      image: granoulas,
      rating: 4.8,
      price: 20,
      newPrice: 18,
      trending: true,
    },
    {
      _id: "887cdd99-1503-4527-aef4-8xcz126a47",
      qty: 55,
      name: "Desk chair",
      description:
        "Chair",
      brand: "El karrita",
      category: "printable",
      //gender: "unisex",
      weight: "8600g",
      arlink:"https://mahergrf.com/elkarrita/?editorid=583&model=https%3A%2F%2Fmahergrf.com%2Ftitouch%2Fchair.gltf",
      quantity: 5,
      image: chair,
      rating: 4.8,
      price: 440,
      newPrice: 400,
      trending: false,
    },
    {
      _id: "887cdd99-1503-4527-aef4-88c521126a47",
      qty: 0,
      name: "Little Rabbit",
      description:
        "Cllay made rabbit.",
      brand: "Persol",
      category: "Clay",
      gender: "Men",
      weight: "100g",
      quantity: 5,
      image: clay2,
      rating: 4.8,
      price: 35,
      newPrice: 32,
      trending: false,
    },
    {
      _id: "dc2b2495-eb6c-4889-a6f7-3c8853a01ba2",
      qty: 0,
      name: "Leaf Necklace",
      description:
        "Clay homemade.",
      brand: "el karrtia",
      category: "Clay",
      gender: "Unisex",
      weight: "750g",
      quantity: 15,
      image: clay3,
      rating: 4.7,
      price: 24,
      newPrice: 22,
      trending: false,
    },
   
    {
      _id: "6033723f-a8db-4ad8-bfda-d191778141aa",
      qty: 0,
      name: "PC/IPad Support",
      description: "PC Support",
      category: "Printable",
      gender: "Women",
      weight: "120g",
      quantity: 9,
      image: Printed4,
      rating: 4.3,
      price: 29,
      newPrice: 25,
    },
    {
      _id: "ac072970-8dac-42fe-8c31-a8fa6fd4b64a",
      qty: 0,
      name: "pencil desk",
     description:"pen.",
      brand: "el karrtia",
      category: "Printable",
     gender: "Unisex",
      weight: "550g",
      quantity: 7,
      image: Printed1,
      rating: 4.8,
      price: 49,
      newPrice: 39,
    },
    {
      _id: "8ad38385-1211-4192-8108-db851b4e0434",
      qty: 0,
      name: "Golden line vase",
      description:"",
      brand: "el karrtia",
      category: "Printable",
      gender: "Unisex",
      weight: "550g",
      arlink:"https://mahergrf.com/elkarrita/?editorid=583&model=https%3A%2F%2Fmahergrf.com%2Ftitouch%2Fvasegold.gltf",
      quantity: 7,
      image: Printed2,
      rating: 4.8,
      price: 17,
      newPrice: 13,
      trending:true,
    },
    {
      _id: "8ad38385-1211-4192-8108-db8rf4e1434",
      qty: 0,
      name: "Leaf earring",
      description:"",
      brand: "el karrtia",
      category: "Retail",
     gender: "Unisex",
      weight: "55g",
      quantity: 2,
      image: ali1,
      rating: 4.8,
      price: 49,
      newPrice: 43,
    },
    {
      _id: "8ad38385-1211-4192-8108-db85frfref2434",
      qty: 0,
      name: "Jasmin earring",
      description:
        "",
      //brand: "Carrera",
      category: "retail",
     // gender: "Unisex",
      weight: "550g",
      quantity: 7,
      image: ali4,
      rating: 4.8,
      price: 49,
      newPrice: 43,
    },
    
    {
      _id: "c76b3840-47d2-4d38-b465-af68e2af9dcd",
      qty: 0,
      name: "Pc Support",
      description:
        "Support for Laptop Pc.",
      brand: "El Karrita",
      category: "Printable",
      gender: "Unisex",
      weight: "100g",
      quantity: 5,
      image: Printed3,
      rating: 2.8,
      price: 12,
      newPrice: 12,
    },
    {
      _id: "c76b3840-47d2-4d38-b465-zzz2af9acd",
      qty: 0,
      name: "Cat Stretch Voronoi",
      description: "this 3D printed cat sculpture embodies the pure joy of a feline yawn and stretch.",
      brand: "El Karrita",
      category: "Printable",
      gender: "Unisex",
      weight: "30g",
      quantity: 5,
      image: tiger,
      rating: 2.8,
      price: 12,
      newPrice: 12,
    },
   {
      _id: "c76b3840-47d2-4d38-b465-af68dzdhtcd",
      qty: 0,
      name: "Goku",
      description: "This dynamic 3D printed sculpture captures the legendary Saiyan warrior, Goku, in a powerful pose.  His feet are planted firmly, one slightly forward for stability, ready to launch into action.  His muscles tense, conveying a sense of focused energy.",
      brand: "El Karrita",
      category: "Printable",
      gender: "Unisex",
      weight: "145g",
      quantity: 5,
      image: goku,
      rating: 5,
      price: 29,
      newPrice: 25,
    },
   {
      _id: "c76b3840-47d2-4d38-b465-afazee2klmdcd",
      qty: 0,
      name: "Venom Symbiotic Spider-Man",
      description: "This unique 3D printed sculpture reimagines the iconic villain Venom in a mesmerizing Voronoi style. The black symbiote that forms Venom's body is rendered as a web of organic, interconnected cells.  These cells bulge and twist, capturing the monstrous form of Venom with a captivating geometric aesthetic.",
      brand: "El Karrita",
      category: "Printable",
      gender: "Unisex",
      weight: "40g",
      quantity: 5,
      image: spider,
      rating: 4,
      price: 19,
      newPrice: 20,
    },
    {
      _id: "c76bfe40-47d2-4d38-b465-afazee2klmdcd",
      qty: 0,
      name: "Small Desk",
      //description: "This unique 3D printed sculpture reimagines the iconic villain Venom in a mesmerizing Voronoi style. The black symbiote that forms Venom's body is rendered as a web of organic, interconnected cells.  These cells bulge and twist, capturing the monstrous form of Venom with a captivating geometric aesthetic.",
      brand: "El Karrita",
      category: "Printable",
      gender: "Unisex",
      weight: "40g",
      quantity: 5,
      image: desk,
      rating: 4,
      price: 19,
      newPrice: 20,
    },
   {
      _id: "c76b3840-47d2-4d38-b465-avc6cvdhfcd",
      qty: 0,
      name: "Kunai",
      description: "This 3D printed kunai captures the essence of the classic ninja throwing weapon with a touch of modern innovation.  The design stays true to the traditional form:",
      brand: "El Karrita",
      category: "Printable",
      gender: "Unisex",
      weight: "60g",
      quantity: 5,
      image: kunai,
      rating: 4,
      price: 18,
      newPrice: 16,
    },
  {
      _id: "c76b3840-47d2-4d38-b465-af68ytytafopsd",
      qty: 0,
      name: "Pain Train Knuckles",
      description: "This 3D printed Knuckles punch isn't just a decoration; it's a dynamic symbol of Knuckles' strength and fighting spirit.",
      category: "Printable",
      gender: "Unisex",
      weight: "20g",
      quantity: 5,
      image: pain,
      rating: 3,
      price: 10,
      newPrice: 9,
    },
  {
      _id: "c76b3840-47d2-4d38-b465-af68iyeh9dcd",
      qty: 0,
      name: "Butterfly Knife",
      description: "This 3D printed butterfly knife captures the essence of the iconic folding knife in a safe and decorative way.",
      gender: "Unisex",
      weight: "35g",
      quantity: 5,
      category: "printable",
      image: butterf,
      rating: 5,
      price: 19,
      newPrice: 17,
    },
  {
      _id: "c76b3840-47d2-4d38-b465-anb5gh9dcd",
      qty: 0,
      name: "Bic Lighter Case",
      description: "This 3D printed Bic lighter case is the perfect marriage of functionality and personalization. It keeps your trusty Bic lighter protected and adds a touch of your own style to this everyday object",
      gender: "Unisex",
      weight: "14g",
      quantity: 5,
      category: "Printable",
      image: bic,
      rating: 5,
      price: 3,
      newPrice: 2,
    },
  {
      _id: "c76b3840-47d2-4d38-b465-af6xxcdcd",
      qty: 0,
      name: "Spiral earrings",
      description: "The world of 3D printed earrings offers a dazzling array of possibilities, pushing the boundaries of traditional jewelry design.",
      gender: "Unisex",
      weight: "1g",
      category: "Printable",
      quantity: 5,
      image: earring,
      rating: 5,
      price: 4,
      newPrice: 2,
    },
  {
      _id: "c76b3840-47d2-4d38-b465-af6vfgf9dcd",
      qty: 0,
      name: "Earrings Feather",
      description: "The design precisely replicates the delicate form of a feather, with a tapered shaft and wispy barbs.",
      gender: "Unisex",
      weight: "1g",
      quantity: 5,
      category: "Printable",
      image: feath,
      rating: 4,
      price: 3,
      newPrice: 2,
    },
  {
      _id: "c76b3840-47d2-4d38-b465-agertb9dcd",
      qty: 0,
      name: "Cascade card wallet",
      description: "This 3D printed card wallet is the perfect blend of modern design, eco-friendly materials, and everyday functionality.",
      gender: "Unisex",
      weight: "34g",
      category: "Printable",
      quantity: 5,
      image: wallet,
      rating: 4,
      price: 19,
      newPrice: 15,
    },
  {
      _id: "c76b3840-47d2-4d38-b465-rrt4cv9dcd",
      qty: 0,
      name: "Honeycomb storage wall",
      description: "This 3D printed honeycomb storage wall takes inspiration from nature's efficient design, offering a stylish and functional solution for organizing your space.",
      gender: "Unisex",
      weight: "57g",
      quantity: 5,
      category: "Printable",
      image: wall,
      rating: 5,
      price: 10,
      newPrice: 9,
    },
  {
      _id: "c76b3840-47d2-4d38-b46tr5-af68e9hk9dcd",
      qty: 0,
      name: "Baby Yoda book stand",
      description: "This adorable 3D printed Baby Yoda book stand adds a touch of intergalactic charm to your bookshelf. May the Pages Be With You ",
      gender: "Unisex",
      weight: "142g",
      quantity: 5,
      category: "Printable",
      image: youda,
      rating: 5,
      price: 16,
      newPrice: 14,
    },
  {
      _id: "c76b3840-47d2-4dert38-b465-af68e5sd9dcd",
      qty: 0,
      name: "Shaker 2L",
      description: "Large capacity water bottle with straw for men and women ",
      gender: "Unisex",
      weight: "50g",
      category: "Retail",
      quantity: 5,
      image: shaker,
      rating: 5,
      price: 60,
      newPrice: 60,
    },
  {
      _id: "c76b3840-47zzed2-4d38-b465-af68e3mw9dcd",
      qty: 0,
      name: "abdominal roller",
      description: "Heavy-duty abdominal roller with non-slip wheels for fitness training",
      gender: "Unisex",
      weight: "1500g",
      category: "Retail",
      quantity: 5,
      image: abdominalroller,
      rating: 5,
      price: 30,
      newPrice: 29,
    },
  
  ];